import * as React from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import { useRef } from 'react'

export const EnterAnimWrapper = ({ children }) => {
  const container = useRef(null)
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start 1', 'start 0.5'],
  })

  const opacity = useTransform(scrollYProgress, [0.3, 1], [0.3, 1])
  const translateY = useTransform(scrollYProgress, [0, 1], [33, 0])

  return (
    <motion.div ref={container} className="" style={{ opacity, translateY }}>
      {children}
    </motion.div>
  )
}

export const EnterAnimWrapperLate = ({ children }) => {
  const container = useRef(null)
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start 0.75', 'start 0.25'],
  })

  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1])
  const translateY = useTransform(scrollYProgress, [0, 1], [33, 0])

  return (
    <motion.div ref={container} className="" style={{ opacity, translateY }}>
      {children}
    </motion.div>
  )
}

export const ImageEnterAnimWrapper = ({ children }) => {
  const container = useRef(null)
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start .99', 'start 0.5'],
  })

  const opacity = useTransform(scrollYProgress, [0.3, 1], [0.3, 1])
  const translateY = useTransform(scrollYProgress, [0, 1], [33, 0])
  const scale = useTransform(scrollYProgress, [0, 1], [0.98, 1])

  return (
    <motion.div
      ref={container}
      style={{
        opacity,
        translateY,
        scale,
      }}
    >
      {children}
    </motion.div>
  )
}

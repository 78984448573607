import React from 'react'
import { LargeText } from './text-styles'
import { motion } from 'framer-motion'

const Footer = () => {
  const emojiVariants = {
    animate: {
      rotate: [0, 25, 0],
      transition: {
        repeat: Infinity,
        duration: 1.25,
        ease: 'easeInOut',
      },
    },
  }

  return (
    <section
      id="say-hi"
      className="md:min-h-screen py-24 mb-32 md:mb-0 flex flex-col justify-center"
    >
      <div className="md:pl-[25vw] md:pr-[10vw]">
        <motion.div
          variants={emojiVariants}
          animate="animate"
          style={{ transformOrigin: '50px 50px' }}
        >
          <LargeText className={'pb-6 md:pb-12'}>👋</LargeText>
        </motion.div>
        <LargeText className={'text-balance'}>
          Say hi through{' '}
          <a
            href="https://www.linkedin.com/in/gabedonner"
            className="hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
          ,{' '}
          <a
            href="https://www.instagram.com/gabedonner"
            className="hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          , or{' '}
          <a
            href="mailto:hi@gabedonner.com"
            className="hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Email
          </a>
        </LargeText>
      </div>
    </section>
  )
}

export default Footer

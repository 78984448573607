import React from 'react'
import { twJoin } from 'tailwind-merge'
import { EnterAnimWrapper } from './enter-animations'

export const LargeText = ({
  children,
  className,
  as: Component = 'h1',
  noAnimation = false,
}) => {
  const content = (
    <Component
      className={twJoin(
        'text-[12vw] leading-[13.5vw] md:text-[6.75vw] md:leading-[7vw] text-balance',
        className
      )}
    >
      {children}
    </Component>
  )

  if (noAnimation) {
    return content
  }

  return <EnterAnimWrapper>{content}</EnterAnimWrapper>
}

export const MediumText = ({
  children,
  className,
  as: Component = 'h2',
  noAnimation = false,
}) => {
  const content = (
    <Component
      className={twJoin(
        'text-[6.25vw] leading-[8.75vw] md:text-[3.25vw] md:leading-[4vw]',
        className
      )}
    >
      {children}
    </Component>
  )

  if (noAnimation) {
    return content
  }

  return <EnterAnimWrapper>{content}</EnterAnimWrapper>
}

export const SmallText = ({
  children,
  className,
  as: Component = 'p',
  noAnimation = false,
}) => {
  const content = (
    <Component
      className={twJoin(
        'text-[16px] leading-[18px] md:text-[18px] md:leading-[22px]',
        className
      )}
    >
      {children}
    </Component>
  )

  if (noAnimation) {
    return content
  }

  return <EnterAnimWrapper>{content}</EnterAnimWrapper>
}

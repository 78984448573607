import './src/styles/global.css'
import React from 'react'
import Layout from './src/components/layout'

// Using the onClientEntry API to set scroll restoration to manual
// This prevents the browser from automatically restoring the scroll position on first POPSTATE event
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onClientEntry

export const onClientEntry = () => {
  window.history.scrollRestoration = 'manual'
}

// This is a function to manually restore scroll position after a page transition
// https://github.com/gatsbyjs/gatsby/issues/28794

const transitionDelay = 333
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.history.scrollRestoration = 'manual'
  const currentPosition = getSavedScrollPosition(location)
  window.setTimeout(() => {
    window.scrollTo(...currentPosition)
  }, transitionDelay)
  return false
}

// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

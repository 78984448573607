import React from 'react'
import '../static/fonts/fonts.css'
import '../styles/global.css'
// import useLenis from '../hooks/useLenis'
import Footer from './footer'
import { AnimatePresence, motion } from 'framer-motion'

export default function Layout({ children, location }) {
  // const fadeVariants = {
  //   initial: {
  //     opacity: 0,
  //     // y: 10,
  //     // scale: 0.99,
  //   },
  //   animate: {
  //     opacity: 1,
  //     y: 0,
  //     // scale: 1,
  //     // transition: { ease: [0.6, 0.01, 0.05, 0.9], delay: 0.1, duration: 0.66 },
  //     transition: {
  //       // ease: [0.95, -0.05, 0.01, 0.6],
  //       // ease: [0.6, 0.01, 0.05, 0.9],
  //       ease: [0.59, 0.01, 0.28, 1],
  //       delay: 0.1,
  //       // duration: 0.66,
  //       duration: 0.24,
  //     },
  //   },
  //   exit: {
  //     opacity: 0,
  //     // scale: 0.99,
  //     // y: -5,
  //     // filter: 'blur(50px)',
  //     transition: {
  //       // ease: [0.95, -0.05, 0.01, 0.6],
  //       // ease: [0.6, 0.01, 0.05, 0.9],
  //       ease: [0.59, 0.01, 0.28, 1],
  //       delay: 0.1,
  //       // duration: 0.66,
  //       duration: 0.24,
  //     },
  //   },
  // }
  const fadeVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.33,
        ease: [0.59, 0.01, 0.28, 1],
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.33,
        ease: [0.59, 0.01, 0.28, 1],
      },
    },
  }
  return (
    <AnimatePresence mode="wait">
      <motion.main
        key={location.pathname} // Key is important for AnimatePresence to detect changes
        variants={fadeVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        style={{ width: '100%' }} // Ensure the div takes full width
        className="font-NeueMontreal overflow-x-hidden px-[5vw] md:px-[2.5vw] pt-[5vw] md:pt-[2.5vw]"
      >
        {children}
        <Footer />
      </motion.main>
    </AnimatePresence>
  )
}
